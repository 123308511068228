/* globals selectboxHandler */

import Swiper from 'swiper'
import Helper from '../utils/helper.js'

export default {
  urlParams: new URLSearchParams(window.location.search),
  url: new URL(Helper.decodeURI(window.location.href)),
  selectedTypes: [],
  selectedMonths: [],
  typesName: {},
  monthsName: {},
  orderBy: 'date',
  $selectType: null,
  $selectMonth: null,
  $selectTypeApply: null,
  $selectMonthApply: null,
  $selectTypeClear: null,
  $selectedTypeSample: null,
  $selectedTypesList: null,
  $selectedTaxoContainer: null,
  $selectedTaxosClear: null,

  init () {
    this.$selectType = $('.selectbox-type')
    this.$selectMonth = $('.selectbox-month')
    this.$selectMonthApply = $('.selectbox-month .selectbox-apply')
    this.$selectTypeApply = $('.selectbox-type .selectbox-apply')
    this.$selectTypeClear = $('.selectbox-type .selectbox-clear')
    this.$selectedTypeSample = $('.selected-taxo.hidden')
    this.$selectedTaxoContainer = $('.selected-taxos')
    this.$selectedTaxosClear = $('.selected-taxos .selected-taxos-remove-all')
    this.$selectedTypesList = $('.selected-taxos-list')
    this.getAllNames()
    this.getAllUrlParams()
    this.updateViews()
    this.initToggleEventView()
    this.initFilterHandler()
    this.initSwipers()
    this.handleRemoveSelectedTaxos()
    this.handleClickOrder()
  },

  getValueFromUrl (variableName, names) {
    const url = new URL(Helper.decodeURI(window.location.href))
    const variable = url.searchParams.get(variableName)

    if (variable) {
      return variable.split(',').map(slug => {
        return {
          value: slug,
          label: names[slug]
        }
      })
    }

    return []
  },

  getAllUrlParams () {
    this.selectedTypes = this.getValueFromUrl('type', this.typesName)
    this.selectedMonths = this.getValueFromUrl('mois', this.monthsName)
    this.orderBy = this.urlParams.get('orderby') ?? 'date'
  },

  getAllNames () {
    this.$selectType.find('.selectbox-option').each((index, option) => {
      const $option = $(option)
      // console.log($option.attr('data-value'), $option.attr('data-label'))
      this.typesName[$option.attr('data-value')] = $option.attr('data-label')
    })

    this.$selectMonth.find('.selectbox-option').each((index, option) => {
      const $option = $(option)
      // console.log($option.attr('data-value'), $option.attr('data-label'))
      this.monthsName[$option.attr('data-value')] = $option.attr('data-label')
    })
  },

  handleClickOrder () {
    $('.grid-head-link').click(event => {
      const order = $(event.currentTarget).data('sort')
      this.urlParams.set('orderby', order)
      this.url.search = this.urlParams.toString()
      window.location.href = this.url.href
    })
  },

  initSwipers () {
    const taxoSwiper = new Swiper('.selected-taxos.swiper', {
      slidesPerView: 'auto',
      spaceBetween: 10
    })
    console.log(taxoSwiper)
  },

  handleRemoveSelectedTaxos () {
    this.$selectedTypesList.on('click', '.selected-taxo-remove', event => {
      // console.log($(event.currentTarget).parents('.selected-taxo').data('slug'))
      const slug = $(event.currentTarget).parents('.selected-taxo').data('slug')
      const taxoName = $(event.currentTarget).parents('.selected-taxo').data('taxo-name')
      this.removeTaxo(slug, taxoName)
    })

    this.$selectedTaxosClear.on('click', event => {
      this.removeAllTaxos()
    })

    this.$selectTypeClear.on('click', event => {
      this.$selectType.find('.selectbox-option').removeClass('selected')
      this.goFilter()
    })
  },

  removeTaxo (slug, taxoName) {
    const $select = taxoName === 'type' ? this.$selectType : this.$selectMonth

    $select.find(`.selectbox-option[data-value="${slug}"]`).removeClass('selected')
    this.goFilter()
  },

  removeAllTaxos () {
    this.$selectType.find('.selectbox-option').removeClass('selected')
    this.$selectMonth.find('.selectbox-option').removeClass('selected')
    this.goFilter()
  },

  addSelectedTaxo (option, taxoName) {
    const $tempSelectedType = this.$selectedTypeSample.clone()

    $tempSelectedType.removeClass('hidden')
    $tempSelectedType.addClass(option.value)
    $tempSelectedType.find('.selected-taxo-label').html(option.label)
    $tempSelectedType.attr('data-slug', option.value)
    $tempSelectedType.attr('data-taxo-name', taxoName)
    // console.log(option.value, taxoName, $tempSelectedType.attr('data-slug'))
    this.$selectedTypesList.prepend($tempSelectedType)
  },

  updateViews () {
    const selectedTypes = this.urlParams.get('type')
    const selectedMonths = this.urlParams.get('mois')
    let count = 0

    if (typeof selectedTypes !== 'undefined' && selectedTypes && selectedTypes !== '') {
      // this.selectedTypes = selectedTypes.split(',')
      selectboxHandler.setSelectedOptions(this.$selectType, selectedTypes.split(','))
    }

    if (typeof selectedMonths !== 'undefined' && selectedMonths && selectedMonths !== '') {
      // this.selectedMonths = selectedMonths.split(',')
      selectboxHandler.setSelectedOptions(this.$selectMonth, selectedMonths.split(','))
    }

    console.log(this.selectedTypes, this.selectedMonths)
    for (const index in this.selectedTypes) {
      const option = this.selectedTypes[index]
      this.addSelectedTaxo(option, 'type')
      count++
    }

    for (const index in this.selectedMonths) {
      const option = this.selectedMonths[index]
      this.addSelectedTaxo(option, 'mois')
      count++
    }

    if (count) {
      this.$selectedTaxoContainer.show()
    } else {
      this.$selectedTaxoContainer.hide()
    }
  },

  initToggleEventView () {
    $('.event-view-toggler').each((index, toggler) => {
      $(toggler).click(() => {
        const $parent = $(toggler).parents('.event')
        $parent.find('.event-documents').slideToggle()
        $parent.toggleClass('open')
      })
    })
  },

  initFilterHandler () {
    this.$selectTypeApply.on('click', () => {
      this.goFilter()
    })
    this.$selectTypeClear.on('click', () => {
      selectboxHandler.clear(this.$selectType)
      this.goFilter()
    })

    this.$selectMonthApply.on('click', () => {
      this.goFilter()
    })
  },

  goFilter () {
    const urlParams = new URLSearchParams('')
    const types = selectboxHandler.getAllSelectedOptions(this.$selectType)
    if (types.length) urlParams.set('type', types.join(','))
    const months = selectboxHandler.getAllSelectedOptions(this.$selectMonth)
    if (months.length) urlParams.set('mois', months.join(','))
    if (this.orderBy !== 'date') {
      urlParams.set('orderby', this.orderBy)
    } else {
      urlParams.delete('orderby')
    }
    urlParams.set('paged', 1)

    this.url.search = urlParams.toString()
    window.location.href = this.url.href
  }
}
