import Swiper from 'swiper'

export default {
  $form: null,
  init () {
    this.$form = $('form.search-header-search')
    this.initNavSwiper()
    this.handleBreakpointMobile()
    this.handleButtonClick()
  },

  handleButtonClick () {
    this.$form.find('.x-button-link').click((e) => {
      this.$form.submit()
    })
  },

  initNavSwiper () {
    const searcSwiper = new Swiper('.search-filters-wrapper-mobile.swiper', {
      slidesPerView: 'auto',
      centerInsufficientSlides: true,
      spaceBetween: 20
    })
  },

  updateInpuSearchPlaceholder (e) {
    const $searchInput = $('input.search-header-search-input')
    if (e.matches) {
      $searchInput.attr('placeholder', $searchInput.attr('data-placeholder-mobile'))
    } else {
      $searchInput.attr('placeholder', $searchInput.attr('data-placeholder'))
    }
  },

  handleBreakpointMobile () {
    const mql = window.matchMedia('(max-width: 1023px)')
    this.updateInpuSearchPlaceholder(mql)
    mql.addEventListener('change', (e) => {
      this.updateInpuSearchPlaceholder(e)
    })
  }
}
