/* globals selectboxHandler */

import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import frLocale from '@fullcalendar/core/locales/fr'
import Sticky from 'sticky-js'
import Helper from '../utils/helper.js'
import Swiper from 'swiper'

export default {
  $selectMonth: null,
  $selectTypes: null,
  $selectedTypeSample: null,
  $selectedTaxoContainer: null,
  $selectedTaxosClear: null,
  $selectedTypesList: null,
  urlParams: new URLSearchParams(Helper.decodeURI(window.location.search)),
  url: new URL(Helper.decodeURI(window.location.href)),
  agendaUrl: '',
  calendarEl: null,
  calendar: null,
  year: 0,
  month: 0,
  eventPositionUpdated: false,
  selectedTypes: [],
  selectedMonth: [],
  events: [],
  sticky: null,
  count: 0,
  view: 'calendar',
  months: ['', 'janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'decembre'],
  init () {
    const calendarEl = document.getElementById('calendar')
    const events = JSON.parse(calendarEl.getAttribute('data-events'))
    this.$selectMonth = $('.selectbox-month')
    this.$selectTypes = $('.selectbox-type')
    this.$selectedTypeSample = $('.selected-taxo.hidden')
    this.$selectedTaxoContainer = $('.selected-taxos')
    this.$selectedTaxosClear = $('.selected-taxos .selected-taxos-remove-all')
    this.$selectedTypesList = $('.selected-taxos-list')
    this.calendarEl = calendarEl
    this.setInitialVars()
    const defaultDate = new Date(this.year, this.month - 1, 1)
    // console.log(events)
    this.events = events
    this.view = Helper.getCookie('agenda_view') || 'calendar'
    const calendar = new Calendar(calendarEl, {
      locale: frLocale,
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
      height: 'auto',
      // defaultDate: '2024-01-01',
      views: {
        dayGridMonth: {
          dayHeaderFormat: {
            weekday: 'long'
          }
        }
      },
      initialView: 'dayGridMonth',
      headerToolbar: {
        left: '',
        center: '',
        right: ''
      },
      viewDidMount: (info) => {
        setTimeout(() => {
          this.addActiveDayClass(info)
          if (this.view === 'calendar') {
            this.handleBreakpointMobile()
          }
          $('#calendar').css('opacity', 1)
          this.getEventsIds()
          this.handelMouseEnterEvents()
        }, 1000)
      },
      eventDidMount: (info) => {
        console.log(info)
      },
      eventClick: (info) => {
        // console.log('click:', info.event, info.event.url)
        if (info.event.url) {
          window.location.href = info.event.url
        }
      },
      events
    })
    calendar.render()
    calendar.gotoDate(defaultDate)
    this.calendar = calendar
    this.handleButtons()
    this.handleSelects()
    this.initMobileView()
    this.initFiltersSticky()
    this.handleSwitchViews()
  },

  initFiltersSticky () {
    this.sticky = new Sticky('.agenda-header-wrapper', {
      // marginTop: 50,
      stickyClass: 'filters-sticky'
    })
  },

  handleBreakpointMobile () {
    this.updateCellHeight()
    $(window).resize(() => {
      if ($(window).width() > 1023) {
        setTimeout(() => {
          this.updateCellHeight()
        }, 1000)
      }
    })
  },

  updateCellHeight () {
    // console.log('updateCellHeight')
    let minHeight = 0
    $('.agenda-content .fc-daygrid-day-frame .fc-daygrid-day-events').each((index, element) => {
      const $events = $(element)
      minHeight = Math.max(minHeight, $events.outerHeight())

      const $children = $events.find('.fc-daygrid-event-harness')
      if (!this.eventPositionUpdated) {
        this.eventPositionUpdated = true
        const $childrenArray = $children.toArray()
        $childrenArray.reverse()
        $events.append($childrenArray)
      }
      $children.each((childIndex, child) => {
        const $child = $(child)
        if ($child.css('position') === 'absolute') {
          console.log('absolute')
          const bottom = $child.css('top')
          $child.css('top', 'initial')
          $child.css('bottom', bottom)
        }

        // console.log($child)
        if (parseInt($child.css('margin-top')) !== 0) {
          // console.log(parseInt($child.css('margin-top')))
          const marginTop = $child.css('margin-top')
          $child.css('margin-bottom', marginTop)
          $child.css('margin-top', 0)
        }
      })
    })

    $('.agenda-content .fc-daygrid-day-frame').each((index, element) => {
      const $frame = $(element)
      $frame.outerHeight(minHeight + 85)
    })
    return minHeight
  },

  addActiveDayClass (info) {
    // console.log(info)
    for (const index in this.events) {
      const between = this.events[index].between

      for (const bIndex in between) {
        const eventDate = between[bIndex].date
        const $tempDay = $('[data-date="' + eventDate + '"]')

        if (!$tempDay.hasClass('fc-day-other')) {
          $tempDay.addClass('active-day-event')
        }
      }
    }
  },

  handleSelects () {
    this.$selectTypes.find('.selectbox-clear').click(event => {
      this.$selectTypes.find('.selectbox-option.selected').removeClass('selected')
    })

    this.$selectTypes.find('.selectbox-apply').click(event => {
      this.setUrlMonthVarFromSelect()
      this.submit()
    })

    this.$selectMonth.find('.selectbox-apply').click(event => {
      this.setUrlMonthVarFromSelect()
      this.submit()
    })
  },

  setInitialVars () {
    this.agendaUrl = this.calendarEl.getAttribute('data-page-url')
    this.year = parseInt(this.calendarEl.getAttribute('data-year'), 10)
    this.month = parseInt(this.calendarEl.getAttribute('data-month'), 10)
    this.selectedTypes = selectboxHandler.getAllSelectedOptionsObject(this.$selectTypes) /// this.urlParams.get('types') ? this.urlParams.get('types').split(',') : []
    this.selectedMonth = selectboxHandler.getAllSelectedOptionsObject(this.$selectMonth)
    console.log(this.selectedTypes, this.selectedMonth)
    let count = 0;

    console.log(this.$selectedTypeSample, this.month)
    for (const index in this.selectedTypes) {
      const option = this.selectedTypes[index]
      count++
      Helper.selectedTaxo.add(this.$selectedTypeSample, this.$selectedTypesList, option, 'type')
    }

    for (const index in this.selectedMonth) {
      const option = this.selectedMonth[index]
      if (this.month !== new Date().getMonth() + 1) {
        count++
        Helper.selectedTaxo.add(this.$selectedTypeSample, this.$selectedTypesList, option, 'mois')
      }
    }

    if (count) {
      this.$selectedTaxoContainer.show()
      const taxoSwiper = new Swiper('.selected-taxos.swiper', {
        slidesPerView: 'auto',
        spaceBetween: 10
      })
    } else {
      this.$selectedTaxoContainer.hide()
    }

    this.$selectedTypesList.on('click', '.selected-taxo-remove', event => {
      // console.log($(event.currentTarget).parents('.selected-taxo').data('slug'))
      const slug = $(event.currentTarget).parents('.selected-taxo').data('slug')
      const taxoName = $(event.currentTarget).parents('.selected-taxo').data('taxo-name')
      let $select = this.$selectMonth
      if (taxoName === 'type') {
        $select = this.$selectTypes
      }
      Helper.selectedTaxo.remove($select, slug)
      this.setUrlMonthVarFromSelect()
      this.submit()
    })

    this.$selectedTaxosClear.on('click', () => {
      Helper.selectedTaxo.removeAll([this.$selectTypes, this.$selectMonth])
      this.setUrlMonthVarFromSelect()
      this.submit()
    })
  },

  gotoPreviousMonth () {
    this.month--
    if (this.month < 1) {
      this.month = 12
      this.year--
    }
    this.setUrlMonthVarFromNextPrevious()
    this.submit()
  },

  gotoNextMonth () {
    this.month++
    if (this.month > 12) {
      this.month = 1
      this.year++
    }
    this.setUrlMonthVarFromNextPrevious()
    this.submit()
  },

  setUrlTypesVar () {
    this.urlParams.set('types', selectboxHandler.getAllSelectedOptions(this.$selectTypes))
  },

  setUrlMonthVarFromNextPrevious () {
    this.url.href = this.agendaUrl + '/' + this.months[this.month] + '-' + this.year
  },

  setUrlMonthVarFromSelect () {
    const monthPath = selectboxHandler.getAllSelectedOptions(this.$selectMonth)[0]
    if (monthPath) {
      this.url.href = this.agendaUrl + '/' + monthPath
    } else {
      this.url.href = this.agendaUrl
    }
  },

  handleButtons () {
    $('.btn-previous-month').click(e => {
      this.gotoPreviousMonth()
    })

    $('.btn-next-month').click(e => {
      this.gotoNextMonth()
    })
  },

  submit () {
    this.setUrlTypesVar()
    if (this.urlParams.get('types').length === 0) {
      this.urlParams.delete('types')
    }
    this.url.search = this.urlParams.toString()
    console.log(this.sanitizeURL(this.url), this.url)
    window.location.href = this.sanitizeURL(this.url).href // + this.url.search
  },

  sanitizeURL (url) {
    url.href = url.href.replace(/\/\//g, '/')
    if (!url.pathname.endsWith('/')) {
      url.pathname += '/'
    }

    const dateActuelle = new Date()
    const moisActuel = dateActuelle.toLocaleString('default', { month: 'long' })
    const anneeActuelle = dateActuelle.getFullYear()
    const moisAnneeActuel = moisActuel.toLowerCase() + '-' + anneeActuelle

    if (url.href.includes(moisAnneeActuel)) {
      url.href = url.href.replace('/' + moisAnneeActuel, '');
    }
    return url
  },

  initMobileView () {
    for (const index in this.events) {
      const event = this.events[index]
      console.log('event', event)

      for (const bIndex in event.between) {
        const date = event.between[bIndex]
        $('.agenda-day-' + date.date + ' .agenda-day-content .container')
          .append('<a href="' + event.url + '"><div class="agenda-day-event" style="background-color: ' + event.color + ';"><div class="agenda-day-event-title">' + event.title + '</></div><div class="agenda-day-event-time">' + event.time + '</div></div></a>')
      }
    }
  },

  getCardOffset ($event, eventOffset, eventId) {
    const paddingY = 30
    const paddingX = 40
    const $eventCard = $('.agenda-hover-card-' + eventId)
    const eventCardHeight = $eventCard.outerHeight()
    const eventCardWidth = $eventCard.outerWidth()
    const agendaHoverOffset = $('.agenda-hover').offset()
    const eventHeight = $event.outerHeight()

    let top = eventOffset.top - agendaHoverOffset.top + eventHeight + paddingY
    let left = eventOffset.left

    // console.log(top, eventCardHeight, $(window).height(), $event.offset())
    if (top + eventCardHeight > $(window).height()) {
      top = eventOffset.top - agendaHoverOffset.top - eventCardHeight - paddingY
    }

    if (left + eventCardWidth > $(window).width()) {
      left = $(window).width() - eventCardWidth - paddingX
    }

    return {
      top,
      left
    }
  },

  getEventsIds () {
    const $fcEvent = $('#calendar .fc-event')

    $fcEvent.each((index, event) => {
      const $event = $(event)
      const tempEventId = this.getEventByTitle($event.find('.fc-event-title').text()).id

      $event.attr('href', '#')
      $event.attr('data-event-id', tempEventId)
      // console.log($event.find('.fc-event-title').text())
    })
  },

  getEventByTitle (title) {
    for (let i = 0; i < this.events.length; i++) {
      const element = this.events[i]
      if (element.title === title) {
        return element
      }
    }

    return null
  },

  handelMouseEnterEvents () {
    const $fcEvent = $('#calendar .fc-event')
    $fcEvent.on('mouseenter', e => {
      const $event = $(e.currentTarget)
      const tempEventId = this.getEventByTitle($event.find('.fc-event-title').text()).id
      const offset = $event.offset()
      const eventOffset = this.getCardOffset($event, offset, tempEventId)
      const $eventCard = $('.agenda-hover-card-' + tempEventId)
      // console.log(offset)
      $eventCard.css({
        top: eventOffset.top + 'px',
        left: eventOffset.left + 'px',
        display: 'block',
        opacity: 1
      })

      setTimeout(() => {
        $eventCard.css({
          display: 'block'
        })
      }, 400)
    })

    $fcEvent.on('mouseleave', e => {
      const $event = $(e.currentTarget)
      const tempEventId = this.getEventByTitle($event.find('.fc-event-title').text()).id
      const $eventCard = $('.agenda-hover-card-' + tempEventId)

      // $eventCard.hide(300)
      $eventCard.css({
        opacity: 0
      })

      setTimeout(() => {
        $eventCard.css({
          display: 'none'
        })
      }, 400)
    })
  },

  toggleViewName () {
    if (this.view === 'grid') {
      this.view = 'calendar'
    } else {
      this.view = 'grid'
    }
    Helper.setCookie('agenda_view', this.view)
  },

  handleSwitchViews () {
    const $switchBtns = $('.agenda-header-switch-btn')
    const $agendaViewContent = $('.agenda-view-content')
    $switchBtns.click(() => {
      this.toggleViewName()
      $switchBtns.toggleClass('not-active')
      $agendaViewContent.slideToggle()
      this.calendar.render()

      const $agenda = $('section.agenda')
      if ($agenda.length) {
        console.log($agenda.offset().top)
        $('html, body').animate({
          scrollTop: $agenda.offset().top - 50
        }, 300)
      }

      setTimeout(() => {
        this.updateCellHeight()
      }, 400)
    })
  }
}
