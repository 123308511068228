/* globals sage, selectboxHandler */

export default {
  selectedRegions: [],
  letter: 'a',
  $selectedRegionsContainer: null,
  $selectedRegionsList: null,
  $selectedRegionSample: null,
  $selectRegionSelect: null,
  $listAdherentUl: null,

  init () {
    this.$selectedRegionsContainer = $('.selected-regions')
    this.$selectedRegionsList = $('.selected-regions-list')
    this.$selectedRegionSample = $('.selected-region.hidden')
    this.$selectRegionSelect = $('.selectbox.selectbox-regions')
    this.$listAdherentUl = $('.annuiare-content-list-adherents')
    this.handleExtraSecteurClick()
    this.handleClickDocument()
    this.updateViews()
    // this.sendRequest()

    // this.$selectRegionSelect.on('onOptionSelected', event => {
    //   this.handleRegionSelection(this.$selectRegionSelect)
    // })

    $('.selectbox-adherent-clear').each((index, element) => {
      $(element).click(event => {
        this.removeAllSelectedRegions()
      })
    })

    $('body').on('click', '.selected-regions-remove-all', () => {
      this.removeAllSelectedRegions()
    })

    $('.annuaire-pagination-letter').click(event => {
      this.handleClickLetter($(event.currentTarget).data('pagination'))
    })

    $('.selectbox-regions .selectbox-apply').click(event => {
      this.handleRegionSelection(this.$selectRegionSelect)
      this.sendRequest()
    })

    this.handleRemoveSelectedRegion()
  },

  handleClickLetter (letter) {
    this.letter = letter
    this.updateViews()
    this.sendRequest()
  },

  handleRemoveSelectedRegion () {
    this.$selectedRegionsList.on('click', '.selected-region-remove', event => {
      console.log('selected')
      const $tempSelectedRegion = $(event.target).closest('.selected-region')
      // console.log($tempSelectedRegion.data('slug'))
      const slug = $tempSelectedRegion.data('slug')

      this.selectedRegions = this.selectedRegions.filter(option => {
        return option.value !== slug
      })
      this.updateViews()
      this.sendRequest()
    })
  },

  handleExtraSecteurClick () {
    $('.annuaire-content').on('click', '.adherent-secteurs-extra-count', event => {
      $(event.currentTarget).closest('.adherent-secteurs-extra').find('.adherent-secteurs-extra-list').slideToggle()
    })
  },

  handleClickDocument () {
    $(document).click(event => {
      if ($(event.target).closest('.adherent-secteurs-extra-count').length === 0) {
        $('.adherent-secteurs-extra-list').slideUp()
      }
    })
  },

  updateViews () {
    const actualLetter = this.letter === 'charp' ? '#' : this.letter
    $('.annuaire-pagination-letter.selected').removeClass('selected')
    $('.annuaire-pagination-letter.letter-' + this.letter).addClass('selected')
    $('.annuaire-content-head-selected-letter').text(actualLetter)
    if (this.selectedRegions.length < 1) {
      this.$selectedRegionsContainer.addClass('hidden')
    } else {
      this.$selectedRegionsContainer.removeClass('hidden')
    }

    this.$selectRegionSelect.find('.selectbox-option.selected').removeClass('selected')

    this.$selectedRegionsList.find('.selected-region').each((index, element) => {
      const $element = $(element)
      if (!$element.hasClass('hidden')) {
        $element.remove()
      }
    })

    for (const index in this.selectedRegions) {
      const option = this.selectedRegions[index]
      const $tempSelectedRegion = this.$selectedRegionSample.clone()

      $tempSelectedRegion.removeClass('hidden')
      $tempSelectedRegion.addClass(option.value)
      $tempSelectedRegion.find('.selected-region-label').html(option.label)
      $tempSelectedRegion.data('slug', option.value)
      this.$selectedRegionsList.prepend($tempSelectedRegion)

      this.$selectRegionSelect.find('.selectbox-option.' + option.value).addClass('selected')
    }
  },

  removeAllSelectedRegions () {
    this.selectedRegions = []
    this.updateViews()
    this.sendRequest()
  },

  handleRegionSelection ($select) {
    this.selectedRegions = selectboxHandler.getAllSelectedOptionsObject($select)
    this.updateViews()
  },

  updateResultViews (data) {
    $('.annuaire-content-head-result-count').text(data.count)
    $('.annuaire-content-adherent:not(.hidden)').remove()

    if (parseInt(data.count)) {
      for (const index in data.results) {
        // console.log(data.results[index])
        const adherent = data.results[index]
        if (typeof adherent.is_test === 'undefined') {
          const $tempAdherent = $('.annuaire-content-adherent.hidden').clone()

          if (typeof adherent.url_logo !== 'undefined') {
            $tempAdherent.find('.adherent-logo-wrapper img').attr('src', adherent.url_logo)
            $tempAdherent.find('.adherent-logo-wrapper img').attr('alt', adherent.title)
            $tempAdherent.find('.adherent-logo-default').remove()
          } else {
            $tempAdherent.find('.adherent-logo-wrapper').remove()
          }
          $tempAdherent.find('.adherent-title').text($('<textarea/>').html(adherent.title).text())
          $tempAdherent.find('.thematique-name').html(adherent.region)
          $tempAdherent.find('.adherent-body-address').html(adherent.adresse)
          $tempAdherent.find('.adherent-body-code').text($('<textarea/>').html(adherent.code_postal + ' ' + adherent.ville).text())
          $tempAdherent.find('.adherent-body-phone').text($('<textarea/>').html(adherent.phone).text())
          $tempAdherent.find('.adherent-secteurs').empty()

          if (typeof adherent.secteurs !== 'undefined' && adherent.secteurs.length) {
            const secteurSize = adherent.secteurs.length
            const secteurCountExtra = secteurSize - 5
            $tempAdherent.find('.adherent-secteurs').append('<ul class="flex flex-wrap gap-3"></ul>')
            const $secteursUl = $tempAdherent.find('.adherent-secteurs ul')

            for (const secteurIndex in adherent.secteurs) {
              // console.log('secteur index:', secteurIndex)
              if (secteurIndex < 5) {
                $secteursUl.append('<li><div class="adherent-secteur">' + adherent.secteurs[secteurIndex] + '</div></li>')
              } else {
                if (secteurCountExtra > 0) {
                  if (parseInt(secteurIndex, 10) === 5) {
                    $secteursUl.append('<li class="adherent-secteurs-extra relative"><a href="#" class="adherent-secteurs-extra-count">+' + secteurCountExtra + '</a><ul class="adherent-secteurs-extra-list absolute"></ul></li>')
                  }
                  $secteursUl.find('.adherent-secteurs-extra-list').append('<li>' + adherent.secteurs[secteurIndex] + '</li>')
                }
              }
            }
          }

          if (typeof adherent.site_web !== 'undefined') {
            const label = $tempAdherent.find('.x-button-link-label').text()
            const preLabel = label.split('test-adherent')
            $tempAdherent.find('.x-button-link-label').text(preLabel[0] + $('<textarea/>').html(adherent.title).text())
            $tempAdherent.find('.x-button-link').attr('href', adherent.site_web)
          } else {
            $tempAdherent.find('.x-button').remove()
          }

          $tempAdherent.slideDown(() => {
            $tempAdherent.removeClass('hidden')
          })
          this.$listAdherentUl.append($tempAdherent)
        }
      }
      $('.annuaire-content-no-result').addClass('hidden')
    } else {
      $('.annuaire-content-no-result').removeClass('hidden')
    }
    this.stopLoading()
  },

  getListSelectedRegionsSlugs () {
    const slug = []

    for (const index in this.selectedRegions) {
      slug.push(this.selectedRegions[index].value)
    }

    return slug
  },

  startLoading () {
    selectboxHandler.closeItem(this.$selectRegionSelect)
    $('.annuaire-header, .annuaire-content, .annuaire-footer').addClass('loading')
  },

  stopLoading () {
    $('.annuaire-header, .annuaire-content, .annuaire-footer').removeClass('loading')
  },

  sendRequest () {
    this.startLoading()
    $.ajax({
      method: 'POST',
      url: sage.ajax_url,
      data: { letter: this.letter, regions: this.getListSelectedRegionsSlugs(), action: 'load_annuaire_adherents_list' }
    })
      .done(result => {
        console.log(result)
        this.updateResultViews(result.data)
      })
  }
}
