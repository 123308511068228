import Swiper from 'swiper'
import Sticky from 'sticky-js'
import sommaire from '../partials/sommaire.js'
import Helper from '../utils/helper.js'

export default {
  sticky: null,
  searchHiddenClass: 'search-hidden',

  init () {
    // utiliser :contains pour ne pas respecter la casse
    $.expr[':'].contains = $.expr.createPseudo(function (arg) {
      return function (elem) {
        return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0
      }
    })

    this.initHeader()
    this.initSommaireSticky()
    this.initAccordions()
    this.initSmoothScroll()
    this.initScrollSpy()
    this.initSearch()
    console.log('convention-collective.js:init')
    Helper.initSearchPlaceHolder($('.convention-header-search .convention-header-search-input'))
    // this.search('condition')
    /**
     * scrollspy : https://codepen.io/zchee/pen/ogzvZZ
     */
    sommaire.init()
  },

  initHeader () {
    const swiper = new Swiper('.convention-header-suggestions', {
      slidesPerView: 'auto',
      centerInsufficientSlides: true,
      spaceBetween: 15,
      loop: true,
      breakpoints: {
        768: {
          spaceBetween: 10
        }
      }
    })
  },

  initSommaireSticky () {
    this.sticky = new Sticky('.convention-content-sommaire-inner')
  },

  initAccordions () {
    $('.convention-content-soustitre-header').click(event => {
      const $header = $(event.currentTarget)

      $header.toggleClass('open')
      $header.next('.convention-content-soustitre-body').slideToggle()
    })

    $('.convention-content-article-header').click(event => {
      const $header = $(event.currentTarget)
      $header.toggleClass('open')
      $header.next('.convention-content-article-body').slideToggle()
    })
  },

  initSmoothScroll () {
    $('a[href*="#"]:not([href="#"])').click(function () {
      const offset = -150
      if (window.location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && window.location.hostname === this.hostname) {
        let target = $(this.hash)

        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']')
        if (target.length) {
          $('html, body').animate({
            scrollTop: target.offset().top + offset
          }, 1000)
          return false
        }
      }
    })
  },

  initScrollSpy () {
    const section = document.querySelectorAll('.convention-content-chapitres .convention-content-chapitre-title')
    const _this = this
    let i = 0

    window.onscroll = function () {
      const scrollPosition = document.documentElement.scrollTop || document.body.scrollTop
      _this.sticky.update()
      const sections = {}

      Array.prototype.forEach.call(section, function (e) {
        if (!$(e).closest('.convention-content-chapitre').hasClass(_this.searchHiddenClass)) { sections[e.id] = $(e).offset().top }
      })

      for (i in sections) {
        if (sections[i] <= scrollPosition + 400) {
          $('.convention-content-sommaire-link').removeClass('active')
          $('a[href*=' + i + ']').addClass('active')
        }
      }
    }
  },

  /**
   * afficher le chapitre parent et son sommaire correspondant
   * @param $element
   */
  showChapitre ($element) {
    const slug = $element.closest('.convention-content-chapitre').find('h2').attr('id')
    const $soustitreHeader = $element.closest('.convention-content-soustitre').find('.convention-content-soustitre-header')
    const $articleHeader = $element.closest('.convention-content-article').find('.convention-content-article-header')

    $element.closest('.convention-content-chapitre').removeClass(this.searchHiddenClass)
    $('[data-slug="' + slug + '"]').removeClass(this.searchHiddenClass)

    $soustitreHeader.addClass('open')
    $articleHeader.addClass('open')
    $soustitreHeader.next('.convention-content-soustitre-body').slideDown()
    $articleHeader.next('.convention-content-article-body').slideDown()
  },

  search () {
    const keywords = $('.convention-header-search .convention-header-search-input').val().trim()
    const $chapitre = $('.convention-content-chapitre')
    const $chapitreSommaire = $('.convention-content-sommaire-chapitre')
    $chapitre.removeClass(this.searchHiddenClass)
    $('.convention-content-chapitre .' + this.searchHiddenClass).removeClass(this.searchHiddenClass)
    $chapitreSommaire.removeClass(this.searchHiddenClass)

    // tester si le keywords n'est pas vide
    if (!/^\s*$/.test(keywords)) {
      $chapitre.addClass(this.searchHiddenClass)
      $('.convention-content-soustitre').addClass(this.searchHiddenClass)
      $('.convention-cta').addClass(this.searchHiddenClass)
      $chapitreSommaire.addClass(this.searchHiddenClass)
      $('.convention-content-article').each((artIndex, artElement) => {
        const $artElement = $(artElement)
        const $artElementSearch = $artElement.find(':contains("' + keywords + '")')
        if ($artElementSearch.length === 0) {
          $artElement.addClass(this.searchHiddenClass)
        } else {
          $artElement.closest('.convention-content-soustitre').removeClass(this.searchHiddenClass)
          this.showChapitre($artElement)
        }
      })

      // s'il ya des h3 qui contiennent le keywords, on affiche le soustitre et le chapitre
      $('.convention-content-soustitre h3:contains("' + keywords + '")').each((stIndex, stElement) => {
        const $stElementSearch = $(stElement)

        $stElementSearch.closest('.convention-content-soustitre').removeClass(this.searchHiddenClass).find('.' + this.searchHiddenClass).removeClass(this.searchHiddenClass)
        this.showChapitre($stElementSearch)
      })

      // s'il y a des h2 qui contiennent le keywords, on affiche le chapitre
      $('.convention-content-chapitre h2:contains("' + keywords + '")').each((chapIndex, chapElement) => {
        this.showChapitre($(chapElement))
      })

      // pour les ctas
      $('.convention-content-chapitre .convention-cta:contains("' + keywords + '")').each((ctaIndex, ctaElement) => {
        const $ctaElementSearch = $(ctaElement)

        $ctaElementSearch.closest('.convention-cta').removeClass(this.searchHiddenClass)
        this.showChapitre($ctaElementSearch)
      })
    }
  },

  initSearch () {
    $('form.convention-header-search').on('submit', event => {
      this.search()
      this.scrollToList()
      return false
    })

    $('.convention-header-search-btn .x-button-link').click(event => {
      this.search()
      this.scrollToList()
    })

    $('.convention-header-suggestions .x-button-taxo .x-button-taxo-link').click(event => {
      const $target = $(event.currentTarget)
      $('.convention-header-search .convention-header-search-input').val($target.find('.x-button-taxo-label').text().trim())
      $('.convention-header-search .convention-header-search-input').trigger('input')
      this.search()
      this.scrollToList()
    })
  },

  scrollToList () {
    const $list = $('.convention-content-sommaire-inner')
    if ($list.length) {
      console.log($list)
      $('html, body').animate({
        scrollTop: $list.offset().top - 50
      }, 1000)
    }
  }
}
