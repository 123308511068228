import Swiper from 'swiper'

export default {
  init () {
    let swiperSommaire = null

    function sommaireSwiper () {
      return new Swiper('.convention-content-sommaire-chapitres', {
        slidesPerView: 'auto',
        spaceBetween: 20
      })
    }

    function handleSommaireSwiper () {
      if ($(window).width() < 1024 && $('.convention-content-sommaire-chapitres').length > 0) {
        if (swiperSommaire === null) {
          swiperSommaire = sommaireSwiper()
          console.log('sommaire initialized')
        }
      } else {
        if (swiperSommaire) {
          swiperSommaire.destroy()
          swiperSommaire = null
          console.log('sommaire destroyed')
        }
      }
    }

    handleSommaireSwiper()

    $(window).on('resize', handleSommaireSwiper)
  }
}
