import Guide from '@scripts/guide'
import '@styles/main.scss'

import Router from './utils/Router.js'
import common from './routes/common.js'
import conventionCollective from './routes/convention-collective.js'
import actu from './partials/actu.js'
import annuaireAdherents from './routes/annuaire-adherents.js'
import pageTemplatePageEspaceDocumentaire from './routes/espace-documentaire.js'
import singleDocuments from './routes/single-document.js'
import pageTemplatePageRecherche from './routes/search.js'
import pageTemplatePageAgenda from './routes/agenda.js'
import pageTemplatePageArchivePress from './routes/presses.js'
import pageTemplatePageDashboard from './routes/espace-connecte.js'
import pageTemplatePageDashboardListFavoris from './routes/favoris-list.js'
import pageTemplatePageDashboardDocumentsPartage from './routes/documents-partages.js'

const routes = new Router({
  common,
  pageTemplatePageConventionCollective: conventionCollective,
  blog: actu,
  annuaireDesAdherents: annuaireAdherents,
  archive: actu,
  pageTemplatePageEspaceDocumentaire,
  singleDocuments,
  pageTemplatePageRecherche,
  pageTemplatePageAgenda,
  pageTemplatePageArchivePress,
  pageTemplatePageDashboard,
  pageTemplatePageDashboardListFavoris,
  pageTemplatePageDashboardDocumentsPartage
})

const { DEV } = import.meta.env

/**
 * Enable guide in dev mode
 */
DEV && new Guide().start()

document.addEventListener('DOMContentLoaded', () => {
  window.$ = jQuery
  window.onIframeLoad = function () {
    console.log('onIframeLoad')
  }
  routes.loadEvents()
})

/* console.log(DEV)
new Header()
initAppearAnim() */
