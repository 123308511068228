/* eslint-disable no-new */
/* globals selectboxHandler */
import Swiper from 'swiper'
import Macy from 'macy' // http://macyjs.com/
import Sticky from 'sticky-js'
import Helper from '../utils/helper.js'

export default {
  masonryGap: 30,
  typesName: {},
  themesName: {},
  anneesName: {},
  selectedTypes: [],
  selectedYears: [],
  selectedThemes: [],
  $selectedTypeSample: null,
  $selectedTaxoContainer: null,
  $selectedTypesList: null,
  $selectTypesSelect: null,
  $selectYearSelect: null,
  $themesFilters: null,
  $searchInput: null,
  $searchForm: null,
  masonry: null,

  init () {
    const url = new URL(Helper.decodeURI(window.location.href))
    console.log('url documentaire:', Helper.decodeURI(url.href))
    this.initHeader()
    this.initPodcastCard()
    this.$selectedTypeSample = $('.selected-taxo.hidden')
    this.$selectedTaxoContainer = $('.selected-taxos')
    this.$selectedTypesList = $('.selected-taxos-list')
    this.$selectedTypesClear = $('.selected-taxos .selected-taxos-remove-all')
    this.$selectTypesSelect = $('.selectbox.selectbox-type')
    this.$selectYearSelect = $('.selectbox-year')
    this.$themesFilters = $('.documentaire-content-filters-list')
    this.$searchInput = $('.documentaire-header-search-input')
    this.$searchForm = $('.documentaire-header-search')
    this.getAllNames()
    this.getAllUrlParams()
    this.initHandleSubmit()

    // this.updateViews()
    // this.initHandleSelectTypes()
    this.initSwipers()
    this.initFiltersSticky()
    Helper.initSearchPlaceHolder($('#wp-block-recherche-input.documentaire-header-search-input'))

    const search = this.$searchInput.val()
    if (search !== '' || this.selectedYears.length || this.selectedThemes.length || this.selectedTypes.length) {
      this.scrollToResult()
    }
    // this.initMasonry()
    // console.log(Masonry)
  },

  initFiltersSticky () {
    this.sticky = new Sticky('.documentaire-content-filters-inner', {
      marginTop: 50,
      stickyClass: 'filters-sticky'
    })
  },

  getAllNames () {
    this.$selectTypesSelect.find('.selectbox-option').each((index, option) => {
      const $option = $(option)
      // console.log($option.attr('data-value'), $option.attr('data-label'))
      this.typesName[$option.attr('data-value')] = $option.attr('data-label')
    })

    this.$selectYearSelect.find('.selectbox-option').each((index, option) => {
      const $option = $(option)
      // console.log($option.attr('data-value'), $option.attr('data-label'))
      this.anneesName[$option.attr('data-value')] = $option.attr('data-label')
    })

    this.$themesFilters.find('.documentaire-content-filters-secteur').each((index, theme) => {
      const $theme = $(theme)
      // console.log($theme.attr('data-theme-slug'), $theme.attr('data-theme-label'))
      this.themesName[$theme.attr('data-theme-slug')] = $theme.attr('data-theme-label')
    })
    // console.log(this.typesName)
  },

  getValueFromUrl (variableName, names) {
    const url = new URL(Helper.decodeURI(window.location.href))
    console.log('url decoded:', Helper.decodeURI(window.location.href))
    const variable = url.searchParams.get(variableName)

    if (variable) {
      return variable.split(',').map(slug => {
        return {
          value: slug,
          label: names[slug]
        }
      })
    }

    return []
  },

  getAllUrlParams () {
    this.selectedTypes = this.getValueFromUrl('types', this.typesName)
    this.selectedThemes = this.getValueFromUrl('themes', this.themesName)
    this.selectedYears = this.getValueFromUrl('annees', this.anneesName)

    // console.log(this.selectedThemes)
    this.updateSelectedTaxo()
  },

  initHeader () {
    /* const swiper = new Swiper('.convention-header-suggestions', {
      slidesPerView: 'auto',
      centerInsufficientSlides: true,
      spaceBetween: 15,
      loop: true,
      breakpoints: {
        768: {
          spaceBetween: 10
        }
      }
    }) */
  },

  initSwipers () {
    new Swiper('.documentaire-content-filters-list-mobile .swiper', {
      slidesPerView: 'auto',
      spaceBetween: 10
    })

    new Swiper('.selected-taxos.swiper', {
      slidesPerView: 'auto',
      spaceBetween: 15
    })

    new Swiper('.documentaire-content-selectboxes-wrapper .swiper', {
      slidesPerView: 'auto',
      spaceBetween: 10
    })
  },

  calculPodcastHeight () {
    const $podcastContainers = $('.card-document.card-document .card-document-iframe')
    $podcastContainers.each((index, container) => {
      const $container = $(container)
      const innerWidth = $container.innerWidth() + 10
      const W = 500
      const H = 225
      const scale = innerWidth / W
      const height = H * scale

      $container.find('.card-document-iframe-inner').css(
        {
          transform: 'scale(' + scale + ')',
          height: height + 125
        })
      $container.css(
        {
          'padding-top': height + 'px'
        }
      )
    })
  },

  initPodcastCard () {
    const $parentPostCards = $('.documentaire-content-result-list')
    if ($parentPostCards.length) {
      const $cardDocumentIframes = $('.card-document-iframe-inner iframe')
      const nbIframes = $cardDocumentIframes.length
      const countIframes = 0
      this.initMasonry()

      if (nbIframes > 0) {
        setInterval(() => {
          this.masonry.reInit()
          this.calculPodcastHeight()
        }, 1000)
      }
    }
  },

  initMasonry () {
    /// console.log('initMasonry')
    this.masonry = Macy({
      container: '.documentaire-content-result-list',
      waitForImages: true,
      columns: 2,
      margin: this.masonryGap,
      breakAt: {
        768: 1
      }
    })
  },

  addSelectedTaxo (option, taxoName) {
    const $tempSelectedType = this.$selectedTypeSample.clone()

    $tempSelectedType.removeClass('hidden')
    $tempSelectedType.addClass(option.value)
    $tempSelectedType.find('.selected-taxo-label').html(option.label)
    $tempSelectedType.attr('data-slug', option.value)
    $tempSelectedType.attr('data-taxo-name', taxoName)
    // console.log(option.value, taxoName, $tempSelectedType.attr('data-slug'))
    this.$selectedTypesList.prepend($tempSelectedType)
  },

  updateSelectedTaxo () {
    this.$selectTypesSelect.find('.selectbox-option.selected').removeClass('selected')
    // console.log(this.$selectedTaxoContainer.find('.selected-taxo:not(.hidden)'))
    this.$selectedTaxoContainer.find('.selected-taxo:not(.hidden)').remove()
    let count = 0

    // console.log(this.selectedTypes, this.selectedYears, this.selectedThemes)
    for (const index in this.selectedTypes) {
      const option = this.selectedTypes[index]
      this.addSelectedTaxo(option, 'type')
      this.$selectTypesSelect.find('.selectbox-option.' + option.value).addClass('selected')
      count++
    }

    this.$selectYearSelect.find('.selectbox-option.selected').removeClass('selected')

    for (const index in this.selectedYears) {
      const option = this.selectedYears[index]
      this.addSelectedTaxo(option, 'annee')
      this.$selectYearSelect.find('.selectbox-option.' + option.value).addClass('selected')
      count++
    }

    // $('.documentaire-content-filters-secteur.active').removeClass('active')

    console.log(this.selectedThemes)
    for (const index in this.selectedThemes) {
      const option = this.selectedThemes[index]
      this.addSelectedTaxo(option, 'theme')
      count++
    }

    // console.log('count', count)
    if (count) {
      this.$selectedTaxoContainer.show()
    } else {
      this.$selectedTaxoContainer.hide()
    }
  },

  scrollToResult () {
    const offset = $(window).width() > 1023 ? 130 : 80
    $([document.documentElement, document.body]).animate({
      scrollTop: $('#documentaire-content-chapitre-title').offset().top - offset
    }, 500)
  },

  updateViews () {

  },

  getSelectedThemes () {
    this.selectedThemes = []
    this.$themesFilters.find('.documentaire-content-filters-secteur.active').each((index, theme) => {
      const $theme = $(theme)
      this.selectedThemes.push({
        value: $theme.attr('data-theme-slug'),
        label: $theme.attr('data-theme-label')
      })
    })
  },

  getAllSelectedParams () {
    const search = this.$searchInput.val()
    let selectedTypes = []
    let selectedYears = []
    let selectedThemes = []
    this.selectedTypes = selectboxHandler.getAllSelectedOptionsObject(this.$selectTypesSelect)
    this.selectedYears = selectboxHandler.getAllSelectedOptionsObject(this.$selectYearSelect)
    this.getSelectedThemes()

    if (this.selectedTypes.length) {
      selectedTypes = this.selectedTypes.map((type) => {
        return type.value
      })
    }

    if (this.selectedYears.length) {
      selectedYears = this.selectedYears.map((type) => {
        return type.value
      })
    }

    if (this.selectedThemes.length) {
      selectedThemes = this.selectedThemes.map((type) => {
        return type.value
      })
    }

    const urlParams = new URLSearchParams('')
    const url = new URL(this.$searchForm.attr('action'))
    if (selectedTypes.length) urlParams.set('types', selectedTypes.join(','))
    if (selectedThemes.length) urlParams.set('themes', selectedThemes.join(','))
    if (selectedYears.length) urlParams.set('annees', selectedYears.join(','))
    if (search !== '') {
      urlParams.set('search_doc', search)
    }
    url.search = urlParams.toString()

    console.log('submit:', url.href)
    window.location.href = url.href
    console.log(urlParams.toString())
    // this.updateSelectedTaxo()
    /// console.log(selectedThemes, selectedTypes, selectedYears, search)
  },

  initHandleSubmit () {
    $('.documentaire-content-filters-secteur').click(event => {
      $('.documentaire-content-filters-secteur.active').removeClass('active')
      $(event.currentTarget).addClass('active')
      this.$searchForm.submit()
    })

    $('.documentaire-content-filters-list-mobile .x-button-taxo-link').click(event => {
      const themeSlug = $(event.currentTarget).parent().attr('data-theme-slug')
      console.log(themeSlug)
      $('.documentaire-content-filters-secteur[data-theme-slug="' + themeSlug + '"]').click()
    })

    this.$searchForm.find('.x-button-link').click(event => {
      this.$searchForm.submit()
    })
    $('.docummentaire-header-suggestion .x-button-taxo-link').click(event => {
      this.$searchInput.val($(event.currentTarget).find('.x-button-taxo-label').text().trim())
      this.$searchForm.submit()
    })
    this.$searchForm.submit(event => {
      event.preventDefault()
      this.getAllSelectedParams()
    })

    this.$selectedTypesList.on('click', '.selected-taxo-remove', event => {
      const $tempSelectedType = $(event.target).closest('.selected-taxo')
      const slug = $tempSelectedType.attr('data-slug')
      const name = $tempSelectedType.attr('data-taxo-name')

      // .log(name)
      switch (name) {
        case 'type' :
          this.selectedTypes = this.selectedTypes.filter(option => {
            // console.log(option.value, slug)
            return option.value !== slug
          })
          break

        case 'annee' :
          this.selectedYears = this.selectedYears.filter(option => {
            // console.log(option.value, slug, option.value !== parseInt(slug, 10))
            return parseInt(option.value, 10) !== parseInt(slug, 10)
          })
          break

        case 'theme' :
          this.selectedThemes = this.selectedThemes.filter(option => {
            // console.log(option.value, slug)
            return option.value !== slug
          })
          $('.documentaire-content-filters-secteur.' + slug).removeClass('active')
          break
      }

      // console.log('before::', this.selectedTypes, this.selectedYears, this.selectedThemes)
      this.updateSelectedTaxo()

      this.getAllSelectedParams()
    })

    $('.selected-taxos-remove-all').click(event => {
      this.selectedTypes = []
      this.selectedYears = []
      this.selectedThemes = []
      $('.documentaire-content-filters-secteur.active').removeClass('active')
      this.$searchInput.val('')
      this.updateSelectedTaxo()

      this.getAllSelectedParams()
    })

    this.$selectTypesSelect.find('.selectbox-apply').click(event => {
      this.getAllSelectedParams()
    })

    this.$selectYearSelect.find('.selectbox-apply').click(event => {
      this.getAllSelectedParams()
    })

    this.$selectTypesSelect.find('.selectbox-clear').click(event => {
      this.$selectTypesSelect.find('.selectbox-option.selected').removeClass('selected')
      this.getAllSelectedParams()
    })

    this.$selectYearSelect.find('.selectbox-clear').click(event => {
      this.$selectYearSelect.find('.selectbox-option.selected').removeClass('selected')
      this.getAllSelectedParams()
    })
  },

  initHandleSelectTypes () {
    /* this.$selectTypesSelect.on('onOptionSelected', () => {
      this.handleSelectType()
    }) */

    this.$selectedTypesList.on('click', '.selected-taxo-remove', event => {
      const $tempSelectedType = $(event.target).closest('.selected-taxo')
      const slug = $tempSelectedType.attr('data-slug')

      this.selectedTypes = this.selectedTypes.filter(option => {
        return option.value !== slug
      })
      /// this.updateViews()
      this.getAllSelectedParams()
    })

    this.$selectedTypesClear.click(() => {
      this.removeAllSelectedTypes()
      this.getAllSelectedParams()
    })
  },

  removeAllSelectedTypes () {
    this.selectedTypes = []
    // this.updateViews()
  }

  /* handleSelectType () {
    this.selectedTypes = selectboxHandler.getAllSelectedOptionsObject(this.$selectTypesSelect)
    // this.updateViews()
  } */
}
