/* globals selectboxHandler */

import Helper from "../utils/helper.js";

export default {
  urlParams: new URLSearchParams(window.location.search),
  url: new URL(Helper.decodeURI(window.location.href)),
  href: '',
  init () {
    this.href = this.url.origin + this.url.pathname.split('/page/')[0]
    this.initOptionSelectedHandler()
  },

  initOptionSelectedHandler () {
    const $selectYear = $('.selectbox-year')
    $selectYear.on('onOptionSelected', () => {
      const selectedYear = selectboxHandler.getAllSelectedOptions($selectYear)[0]
      if (selectedYear !== 'non') {
        window.location.href = this.href + '/?annee=' + selectedYear
      } else {
        window.location.href = this.href
      }
    })
  }
}
