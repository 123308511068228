import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
import $ from 'jquery'
import 'select2/dist/css/select2.min.css'
import 'select2'

export default {
  init () {
    let swiperEventDoc
    let swiperCtaList
    let swiperTabDoc

    const $block = $('.espace-adherent-content')
    this.handlePodcastHeights($block)

    if ($(window).width() < 768 && $('.event-doc-swiper').length > 0) {
      this.initEventDocSwiper()
      this.initCtaListSwiper()
      this.initTabDocSwiper()
      this.tabHeaderSwiper()
    }

    $(window).on('resize', () => {
      if ($(window).width() < 768 && !swiperEventDoc) {
        this.initEventDocSwiper()
      } else if ($(window).width() > 768 && !swiperEventDoc) {
        this.destroyEventDocSwiper()
      }

      if ($(window).width() < 768 && !swiperCtaList) {
        this.initCtaListSwiper()
      } else if ($(window).width() > 768 && !swiperCtaList) {
        this.destroyCtaListSwiper()
      }

      if ($(window).width() < 768 && !swiperTabDoc) {
        this.initTabDocSwiper()
      } else if ($(window).width() > 768 && !swiperTabDoc) {
        this.destroyTabDocSwiper()
      }
    })

    const screenWidth = window.innerWidth

    if (screenWidth > 768) {
      const $newFirstTab = $('.bloc-abonnements-tabs .tab-header .tab-item:first .tab-link')

      setTimeout(function () {
        $newFirstTab.addClass('active')
        $newFirstTab.click()
      }, 10)
    } else {
      const $newFirstTabMobile = $('.bloc-abonnements-tabs .tab-header .swiper-slide:first .tab-link')

      setTimeout(function () {
        $newFirstTabMobile.addClass('active')
        $newFirstTabMobile.click()
      }, 10)
    }

    $('.bloc-abonnements-tabs .tab-header a').on('click', function (e) {
      e.preventDefault()
      const tabId = $(this).attr('href')

      $('.tab-content .tab-pane').removeClass('show active')

      $(tabId).addClass('show active')

      $('.bloc-abonnements-tabs .tab-header a').removeClass('active')
      $(this).addClass('active')
    })
  },

  handlePodcastHeights ($block) {
    setInterval(() => {
      this.calculPodcastHeight($block)
    }, 1000)
  },

  calculPodcastHeight ($block) {
    $block.find('.card-document.card-document .card-document-iframe').each((index, iframeInner) => {
      const width = $(iframeInner).innerWidth() + 10
      if (this.cardInnerWidth === width) {
        return
      }
      this.cardInnerWidth = width
      const innerWidth = width
      const W = 500
      const H = 225
      const scale = innerWidth / W
      const height = H * scale
      // const heightScale = height * scale
      $block.find('.card-document-iframe-inner').css(
        {
          transform: 'scale(' + scale + ')',
          height: height + 125
        })
      $block.find('.card-document-iframe').css(
        {
          'padding-top': height + 'px'
        }
      )
    })
  },

  initEventDocSwiper () {
    this.swiperEventDoc = new Swiper('.event-doc-swiper', {
      modules: [Navigation, Pagination],
      slidesPerView: 1.01,
      spaceBetween: 10,
      pagination: {
        el: '.swiper-pagination'
      },
      breakpoints: {
        768: {
          slidesPerView: 2
        },
        1024: {
          spaceBetween: 0
        }
      }
    })
  },

  destroyEventDocSwiper () {
    if (this.swiperEventDoc) {
      this.swiperEventDoc.destroy()
      this.swiperEventDoc = null
    }
  },

  initCtaListSwiper () {
    this.swiperCtaList = new Swiper('.trois-ctas-list', {
      modules: [Navigation, Pagination],
      slidesPerView: 1.01,
      spaceBetween: 10,
      pagination: {
        el: '.swiper-pagination'
      },
      breakpoints: {
        768: {
          spaceBetween: 30
        },
        1024: {
          spaceBetween: 0
        }
      }
    })
  },

  destroyCtaListSwiper () {
    if (this.swiperCtaList) {
      this.swiperCtaList.destroy()
      this.swiperCtaList = null
    }
  },

  initTabDocSwiper () {
    this.swiperTabDoc = new Swiper('.tab-doc-swiper', {
      modules: [Navigation, Pagination],
      slidesPerView: 1.01,
      spaceBetween: 10,
      pagination: {
        el: '.swiper-pagination'
      },
      breakpoints: {
        768: {
          slidesPerView: 2
        }
      }
    })
  },

  destroyTabDocSwiper () {
    if (this.swiperTabDoc) {
      this.swiperTabDoc.destroy()
      this.swiperTabDoc = null
    }
  },

  tabHeaderSwiper () {
    this.headerSwiper = new Swiper('.tab-header.mobile', {
      slidesPerView: 'auto',
      centerInsufficientSlides: true,
      spaceBetween: 10,
      loop: true,
      allowTouchMove: true,
      grabCursor: true
    })
  }
}
