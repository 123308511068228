/* globals selectboxHandler, sage */

import Swiper from 'swiper'
import Helper from '../utils/helper.js'

export default {
  urlParams: new URLSearchParams(window.location.search),
  url: new URL(Helper.decodeURI(window.location.href)),
  selectedTypes: [],
  typesName: {},
  $selectedTaxoContainer: null,
  $selectedTypeSample: null,
  $selectedTypesList: null,
  init () {
    this.$selectType = $('.select-favoris-type')
    this.$selectApply = $('.select-favoris-type .selectbox-apply')
    this.$selectClear = $('.select-favoris-type .selectbox-adherent-clear')
    this.$searchInput = $('#list-favoris-recherche-input')
    this.$searchForm = $('.list-favoris-recherche-form')
    this.$selectedTaxoContainer = $('.selected-taxos')
    this.$selectedTypeSample = $('.selected-taxo.hidden')
    this.$selectedTypesList = $('.selected-taxos-list')
    this.$selectedTaxosClear = $('.selected-taxos .selected-taxos-remove-all')
    this.$searchSubmit = $('.list-favoris-recherche-form .list-favoris-recherche-submit')
    this.$allRemoveButton = $('.button-remove-favoris')
    console.log('previous link: ', this.previousLink)
    this.previousLink = $('.list-favoris-content').data('previous-link')
    if ($('.list-favoris-empty').length && typeof this.previousLink !== 'undefined' && this.previousLink !== '') {
      window.location.href = this.previousLink
    }

    this.initSelectedTaxo()
    this.updateViews()
    console.log('init favoris list')
    // console.log(this.$selectType, this.$selectApply, this.$selectClear, this.$searchInput)
    this.initEventHandler()
    this.initRemoveFavoris()
    this.calculPodcastWidth()

    $(window).on('resize', () => {
      this.calculPodcastWidth()
    })

    /* setInterval(() => {
      this.calculPodcastWidth()
    }, 1000) */
  },

  getAllNames () {
    this.$selectType.find('.selectbox-option').each((index, option) => {
      const $option = $(option)
      // console.log($option.attr('data-value'), $option.attr('data-label'))
      this.typesName[$option.attr('data-value')] = $option.attr('data-label')
    })
  },

  getValueFromUrl (variableName, names) {
    const url = new URL(Helper.decodeURI(window.location.href))
    const variable = url.searchParams.get(variableName)

    if (variable) {
      return variable.split(',').map(slug => {
        return {
          value: slug,
          label: names[slug]
        }
      })
    }

    return []
  },

  addSelectedTaxo (option, taxoName) {
    const $tempSelectedType = this.$selectedTypeSample.clone()

    $tempSelectedType.removeClass('hidden')
    $tempSelectedType.addClass(option.value)
    $tempSelectedType.find('.selected-taxo-label').html(option.label)
    $tempSelectedType.attr('data-slug', option.value)
    $tempSelectedType.attr('data-taxo-name', taxoName)
    // console.log(option.value, taxoName, $tempSelectedType.attr('data-slug'))
    this.$selectedTypesList.prepend($tempSelectedType)
  },

  initSelectedTaxo () {
    this.getAllNames()
    this.selectedTypes = this.getValueFromUrl('type', this.typesName)

    let count = 0
    for (const index in this.selectedTypes) {
      const option = this.selectedTypes[index]
      this.addSelectedTaxo(option, 'type')
      count++
    }

    if (count) {
      this.$selectedTaxoContainer.show()
      new Swiper('.selected-taxos.swiper', {
        slidesPerView: 'auto',
        spaceBetween: 10
      })
    } else {
      this.$selectedTaxoContainer.hide()
    }

    this.$selectedTypesList.on('click', '.selected-taxo-remove', event => {
      // console.log($(event.currentTarget).parents('.selected-taxo').data('slug'))
      const slug = $(event.currentTarget).parents('.selected-taxo').data('slug')
      const taxoName = $(event.currentTarget).parents('.selected-taxo').data('taxo-name')
      this.removeTaxo(slug, taxoName)
    })

    this.$selectedTaxosClear.on('click', event => {
      this.removeAllTaxos()
    })
  },

  removeTaxo (slug, taxoName) {
    const $select = taxoName === 'type' ? this.$selectType : this.$selectMonth

    $select.find(`.selectbox-option[data-value="${slug}"]`).removeClass('selected')
    this.goFilter()
  },

  removeAllTaxos () {
    this.$selectType.find('.selectbox-option').removeClass('selected')
    this.goFilter()
  },

  calculPodcastWidth () {
    $('.card-document-podcast').each((index, podcastCard) => {
      const $card = $(podcastCard)
      const actualWidth = $card.find('.card-document-content').innerWidth()
      let width = (actualWidth / 0.6) + 10
      // let scale = 0.6
      if (width < 500) {
        $card.find('.card-document-iframe').css('transform', 'scale(0.92) translateX(-4%)')
        width = 500
      } else {
        $card.find('.card-document-iframe').css('transform', 'none')
      }
      $card.find('iframe').width(width)
    })
  },

  updateViews () {
    const selectedTypes = this.urlParams.get('type')
    const search = this.urlParams.get('term')
    if (typeof selectedTypes !== 'undefined' && selectedTypes && selectedTypes !== '') {
      console.log(this.urlParams.get('type'))
      selectboxHandler.setSelectedOptions(this.$selectType, selectedTypes.split(','))
    }
    if (typeof search !== 'undefined' && search !== '') {
      this.$searchInput.val(search)
      console.log(this.urlParams.get('term'))
    }
  },

  initEventHandler () {
    console.log('initEventHandler')
    this.$selectApply.on('click', () => {
      this.goFilter()
    })
    this.$selectClear.on('click', () => {
      selectboxHandler.clear(this.$selectType)
      this.goFilter()
    })

    this.$searchForm.on('submit', (event) => {
      event.preventDefault()
      this.goFilter()
    })

    this.$searchSubmit.on('click', (event) => {
      event.preventDefault()
      this.goFilter()
    })
  },

  initRemoveFavoris () {
    this.$allRemoveButton.on('click', (event) => {
      const dataId = event.currentTarget.getAttribute('data-id')
      this.deleteFavoris(dataId)
    })
  },

  deleteFavoris (dataId) {
    $.ajax({
      method: 'POST',
      url: sage.ajax_url,
      data: { post_id: dataId, action: 'delete_favorite' }
    })
      .done(result => {
        window.location.reload()
      })
  },

  goFilter () {
    const urlParams = new URLSearchParams('')
    const types = selectboxHandler.getAllSelectedOptions(this.$selectType)
    if (types.length) urlParams.set('type', types.join(','))
    // console.log(types)
    const search = this.$searchInput.val()
    const termSearch = this.$searchInput.val()
    console.log('search:', search, termSearch)
    if (typeof search !== 'undefined' && search !== '') {
      urlParams.set('term', search)
    }
    urlParams.set('paged', 1)
    console.log(this.url)
    this.url.search = urlParams.toString()
    window.location.href = this.url.href
  }
}
